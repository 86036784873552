import { questionsToMap } from 'utils/formValuesUtils';
import promiseDebounce from 'utils/promiseDebounce';
import { getQuestionOptions } from 'app-requests/triadms-apis/getQuestionOptions';
import handleDynamicOptions, {
  getQuestionsWithFieldAsDependency,
} from './handleDynamicOptions';
import setDynamicOptions from '../reducer-functions/setDynamicOptions';

export const debouncedRequestOptions = promiseDebounce(getQuestionOptions, 600);

// TODO: [T1-10430] Investigate why we are dispatching here. We should be able to just return the updatedState and consolidate a single dispatch at the end.
//       The dispatch here in this function is updating the state but the consumers of this function are also updating the state and unaware of the new state updates.
//       This function should ONLY be processing the dynamic options and returning the updated state that contains these options.
//       The consumers of this function should be responsible for updating the state as it has the full picture of what is happening.
//       I added a patch to run the reducer function and return the updated state as an argument but this can be fragile if we were ever to update what SET_DYNAMIC_OPTIONS dispatches.
/**
 * @summary use this to load dynamic option for when an answer to a question changes
 * @param {Object} formValues - the current state of the form
 * @param {string} updatedValue - then name of the question updated
 */
export default function processDynamicOptions(
  formValues,
  state,
  dispatch,
  updatedValue,
  options = {}
) {
  const {
    allQuestionsInForm,
    allDynamicOptionQuestions,
    formConfigs,
    fieldNameMap,
  } = state;

  const optionsToReq = handleDynamicOptions({
    allQuestionsInForm,
    allDynamicOptionQuestions,
    formValues,
    updatedValue,
    fieldNameMap,
  });

  const optionRequest = options.onOptionsRequest || debouncedRequestOptions;

  if (optionsToReq) {
    dispatch({ type: 'SET_STATUS', isProcessing: true });
    // Note her is either optionsToReq || subjectsReq because subjectsReq only happens on load
    return optionRequest(
      optionsToReq,
      formConfigs.schoolCode,
      formConfigs.variant,
      undefined,
      options.taxonomyValues
    ).then((questionOptionsMap) => {
      // note that this reducer can update form values
      dispatch({
        type: 'SET_DYNAMIC_OPTIONS',
        dynamicOptions: questionOptionsMap,
      });
      dispatch({ type: 'SET_STATUS', isProcessing: false });
      return {
        questionOptionsMap,
        updatedState: setDynamicOptions(state, {
          dynamicOptions: questionOptionsMap,
        }),
      };
    });
  }

  // Case where a question was cleared out. Note that sometimes we call this function with no updatedValue which would reprocess the whole form
  if (updatedValue && typeof formValues[updatedValue] === 'undefined') {
    const affectedDependentFields = getQuestionsWithFieldAsDependency(
      allDynamicOptionQuestions,
      updatedValue,
      allQuestionsInForm
    ).map((question) => ({
      questionId: question.questionId,
      options: question.options,
    }));

    if (affectedDependentFields.length) {
      // We are using the options directly from affectedDependentFields so we can reset their options. In most cases they are empty set
      const updatedDynamicOptions = questionsToMap(affectedDependentFields);
      // note that this reducer can update form values
      dispatch({
        type: 'SET_DYNAMIC_OPTIONS',
        dynamicOptions: updatedDynamicOptions,
      });

      return Promise.resolve({
        questionOptionsMap: updatedDynamicOptions,
        updatedState: setDynamicOptions(state, {
          dynamicOptions: updatedDynamicOptions,
        }),
      });
    }
  }

  return Promise.resolve({
    questionOptionsMap: state.formStatus.dynamicOptions,
    updatedState: state,
  });
}
