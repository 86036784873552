/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useState } from 'react';
import ReactSelect from 'components/base/selects/base-select/ReactSelect';
import CloseBtn from 'components/base/closeBtn/CloseBtn';
import MultiSchoolSelectContext from 'components/base/inputs/multiSchoolSelect/multiSchoolSelectContext';
import { Terms } from 'components/base/inputs/multiSchoolSelect/Terms';
import Wysiwyg from 'components/blocks/wysiwyg/Wysiwyg';
import SchoolLogo from '../SchoolLogo';
import { SelectionCardActions } from './SelectionCardActions';

interface SelectionCardExpandedProps {
  id: string;
  termsRef: React.RefObject<HTMLDivElement>;
  contentRef: React.RefObject<HTMLDivElement>;
}
MultiSchoolSelectContext;
export function SelectionCardExpanded(
  props: SelectionCardExpandedProps
): React.JSX.Element {
  const { id, termsRef, contentRef } = props;
  const { handleCloseCard, handleProgramChange, handleSkipSchool, state } =
    useContext(MultiSchoolSelectContext);

  const {
    selectedProgram,
    isAccepted,
    isSkipped,
    schoolInfo: {
      schoolLogo,
      label,
      featuredImage,
      matchingProgramOptions: options,
      terms,
      submitText,
      hasTcpaCheckbox,
    },
  } = state[id];

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(hasTcpaCheckbox);
  const [errorMessage, setErrorMessage] = useState('');

  function handleTcpaCheckboxToggle(hasAccepted: boolean): void {
    setIsSubmitDisabled(!hasAccepted);
    setErrorMessage('');
  }

  function _handleCloseCard(): void {
    handleCloseCard(id);
    handleSkipSchool(id);
  }

  return (
    <div className="selectionCard__back" data-tf-element-role="offer">
      <span className="selectionCard__closeBtn">
        <CloseBtn onClick={_handleCloseCard} />
      </span>
      <div className="selectionCard__featuredImage">
        <img src={featuredImage.mobile} alt="" />
      </div>
      <div className="selectionCard__body">
        <div className="selectionCard__meta">
          <div className="selectionCard__logo">
            <SchoolLogo
              logo={schoolLogo}
              className="selectionCard__logoImg"
              alt={`${label} logo`}
            />
          </div>
          <h4
            className="selectionCard__title"
            data-tf-element-role="consent-advertiser-name"
          >
            <Wysiwyg content={label} isClean />
          </h4>
        </div>
        <div className="selectionCard__content" ref={contentRef}>
          <div className="selectionCard__formCtrl">
            <div className="selectionCard__formLabel">
              <label htmlFor="selectCardSelection">Best Matching Program</label>
            </div>
            <div className="selectionCard__formField">
              <ReactSelect
                options={options}
                value={selectedProgram}
                onChange={(program) => handleProgramChange(id, program)}
                name="selectCardSelection"
              />
            </div>
          </div>
          <div
            className={
              errorMessage
                ? 'selectionCard__terms selectionCard__terms--hasError'
                : 'selectionCard__terms'
            }
            ref={termsRef}
          >
            <Terms
              terms={terms}
              schoolName={label}
              isAccepted={isAccepted}
              isSkipped={isSkipped}
              onTcpaCheckboxToggle={handleTcpaCheckboxToggle}
              hasTcpaCheckbox={hasTcpaCheckbox}
            />
          </div>
          {errorMessage && (
            <span className="selectionCard__errorMessage">{errorMessage}</span>
          )}
        </div>
      </div>
      <div className="selectionCard__actions">
        <SelectionCardActions
          id={id}
          showSelect={false}
          showSubmit
          showCheckbox={false}
          submitText={submitText}
          isSubmitDisabled={isSubmitDisabled}
          onError={() =>
            setErrorMessage(
              'Please accept the terms by checking the checkbox above'
            )
          }
        />
      </div>
    </div>
  );
}
