import get from 'lodash/get';
import { LogDebug, LogError } from 'utils/logging';
import request from 'utils/request';
import {
  TRIADMS_BACKEND,
  TRIAD_PROXY_ROUTE,
  WP_API_URL,
} from 'app-requests/apiConstants';
import {
  getDOMFieldValue,
  isValidZip,
  questionsToMap,
} from 'utils/formValuesUtils';
import { formValuesToRequestArr } from 'utils/form-utils/formValuesToRequestArr';
import isBrowser from 'utils/isBrowser';
import {
  getAllCookies,
  getStoredQueryParams,
  getUserSessionId,
  getPageViewId,
} from 'utils/analyticsHelpers';
import { QUERY_PARAMS, QUESTION_IDS } from 'consts';
import { appendGAValue } from 'utils/thirdPartyScripts';
import { retryablePromise } from 'utils/generalUtils';
import { getSchoolLogoUrlMap } from 'utils/imageHelpers';
import { extractUserTrackingFilters } from 'utils/session/extractUserTrackingFilters';
import parseWpQuestionnaires from './transformers/parseWpQuestionnaires';

const { SHARED_EDU_ID, SHARED_SESSION_ID } = QUERY_PARAMS;

/**
 * @summary this is used to get all questions in the question bank
 */
export async function getQuestionBank(redisHelpers) {
  try {
    const path = isBrowser() ? TRIAD_PROXY_ROUTE : TRIADMS_BACKEND;

    if (!isBrowser()) {
      const bank = await redisHelpers.getQuestionBank();
      if (bank) {
        return Promise.resolve(bank);
      }
    }

    const { questionBank } = await request({
      method: 'get',
      url: `${path}/questionBank`,
    });
    const parsedBank = questionsToMap(questionBank);

    if (!isBrowser()) {
      redisHelpers.setQuestionBank(parsedBank);
    }

    return parsedBank;
  } catch (error) {
    LogError(`getQuestionBank Errors: ${error.message}`);
    throw error;
  }
}

/**
 * @summary this is used to get the questionnaire for a micro site
 * @param {String} domain - the domain of this microsite
 * @param {String} variant - the AB test variant to use
 * @param {Object} questionBankMap - All Questions in TriadSystem
 * @param {Object} redisHelpers - All Questions in TriadSystem
 */
export async function getQuestionnaires(args) {
  const { domain, schoolCode, redisHelpers } = args;
  if (!schoolCode || !domain) {
    LogError('getQuestionnaires: No School Code Found in Wordpress Configs', {
      domain,
    });
  }

  const questionnaires = await redisHelpers.getQuestionnaires();
  if (questionnaires) {
    return Promise.resolve(questionnaires);
  }

  return request({
    isWP: true,
    url: `${WP_API_URL}/triad_questionnaire`,
    query: {
      frontendDomain: domain,
    },
  })
    .then((wpQuestionnaires) => {
      const parsedResponse = parseWpQuestionnaires(wpQuestionnaires, args);
      redisHelpers.setQuestionnaires(parsedResponse);
      return parsedResponse;
    })
    .catch((error) => {
      LogError(`getQuestionnaires Errors: ${error.message}`, {
        domain,
      });
      throw error;
    });
}

/**
 * @summary use this to log current progress for user's session so we can get it back in the getProfile API
 * @param {Object} formValues - current form state
 * @param {Object} fieldNameMap - map of all field names
 * @param {String} config.schoolCode - this comes from wordpress to tell us the code for this school
 * @param {String} config.variant - the questionnaire version we are showing user, A/B test
 * @param {Object} linkedSessionFormValues - form values that could have come from another session
 * @param {number} lastQuestionAnswered - id of the last question answered
 * @param {?String=} endpoint - the endpoint to hit
 */
let didAlert = false;
function _logProgress(
  formValues,
  fieldNameMap,
  { schoolCode, variant },
  linkedSessionFormValues,
  lastQuestionAnswered,
  endpoint = '/questionLog'
) {
  const userSessionId = getUserSessionId();
  const { queryParamMap } = getStoredQueryParams();

  if (!userSessionId && !didAlert) {
    didAlert = true;
    LogError('No Session Cookie Found When Logging Question', {
      schoolCode,
      variant,
      formAnsweredQuestion: Object.keys(formValues).join(', '),
    });
    return Promise.resolve({});
  }

  if (userSessionId && !getPageViewId()) {
    LogDebug('No Page View Id found but a user session was detected');
  }

  return request({
    method: 'post',
    url: `${TRIAD_PROXY_ROUTE}${endpoint}`,
    body: {
      schoolCode,
      templateName: variant,
      questionReplies: formValuesToRequestArr(
        formValues,
        fieldNameMap,
        false,
        linkedSessionFormValues
      ),
      currentQuestionId: lastQuestionAnswered,
      [SHARED_EDU_ID]: queryParamMap[SHARED_EDU_ID],
      [SHARED_SESSION_ID]: queryParamMap[SHARED_SESSION_ID],
    },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // We do not want to throw error. Let the user continue on
      return error;
    });
}

/**
 * @summary a proxy function for _logProgress so that we may call the last one
 * @param  {...any} args - see above function _logProgress
 */
let logProgressInterval = null;
export function logProgress(...args) {
  clearInterval(logProgressInterval);

  // if we have a user session then just call the API
  if (getUserSessionId()) {
    return _logProgress(...args);
  }

  logProgressInterval = setInterval(() => {
    if (getUserSessionId()) {
      clearInterval(logProgressInterval);
      _logProgress(...args);
    }
  }, 1500);

  return Promise.resolve({});
}

/**
 * @summary used to track needed session ids for google to track offsite conversions
 */
let isCurrentSessionTracked = false;

export function forTestingOnlyClearIsCurrentSessionTracked() {
  isCurrentSessionTracked = false;
}

export function getUsersGoogleSessionIds() {
  const cookies = getAllCookies();
  return cookies.keys
    .filter((key) => key.match(/^(_ga|_gcl_)/))
    .reduce((keyValueArray, key) => {
      keyValueArray.push({
        name: key,
        value: cookies.map[key],
      });

      return keyValueArray;
    }, []);
}

export function trackUsersGoogleSessionIds() {
  if (isCurrentSessionTracked) {
    return Promise.resolve();
  }

  isCurrentSessionTracked = true;

  return request({
    method: 'post',
    url: `${TRIAD_PROXY_ROUTE}/SetUserMetadata`,
    body: {
      metadata: getUsersGoogleSessionIds(),
    },
  });
}

/**
 * @summary used to validate the user's zip code
 * @param {String} args.zip - zip code
 */
const browserZipPromiseCache = {};
export function getDataForZip({ zip }) {
  if (browserZipPromiseCache[zip]) {
    return browserZipPromiseCache[zip];
  }

  if (!isValidZip(zip)) {
    return Promise.resolve({ isValid: false });
  }

  browserZipPromiseCache[zip] = request({
    method: 'post',
    url: `${TRIAD_PROXY_ROUTE}/zipCheck`,
    body: {
      zipCode: zip,
    },
  })
    .then((res) => {
      return {
        ...res,
        isValid: !!(res.state && res.city && zip === res.zipCode),
        suggestion: zip !== res.zipCode ? res.zipCode : '',
      };
    })
    .catch(() => {
      return { isValid: true };
    });

  return browserZipPromiseCache[zip];
}

/**
 * @summary builds the url and parameters that make up the get request to retrieve
 * a new session id from the backend
 */
export function buildGenerateUserSessionIdRequest(queryParams, siteMeta = {}) {
  const requestPayload = {
    deadlineTimeout: 5000,
    responseTimeout: 5000,
    url: `${TRIAD_PROXY_ROUTE}/GetNewSession`,
    query: {
      tmsurl: window.location.pathname,
      tmshost: window.location.host,
      tmsaq: 1,
      schoolCode: siteMeta.schoolCode,
    },
  };

  if (queryParams) {
    Object.keys(queryParams).forEach((param) => {
      requestPayload.query[param] = queryParams[param];
    });
  }

  return requestPayload;
}

/**
 * @summary used to get a new user session token if not in a cookie value
 */
export function generateUserSessionId(queryParams, siteMeta) {
  const sessionRequest = buildGenerateUserSessionIdRequest(
    queryParams,
    siteMeta
  );
  const sessionIdRequest = () =>
    request(sessionRequest).then((sessionResponse) => {
      const {
        SessionId,
        PageViewId,
        trace,
        spc,
        ppc,
        propertyOrigin,
        campaignType,
        trackingSchoolCode,
        isClickUser = false,
        adPlatformSource,
      } = sessionResponse;

      spc && appendGAValue('spc', spc);
      ppc && appendGAValue('ppc', ppc);

      if (SessionId === '00000000-0000-0000-0000-000000000000') {
        LogError('Session Error: token not unique', {
          trace,
        });
      }

      if (!SessionId) {
        LogError('Session Error: empty sessionId received', {
          trace,
          pageViewId: PageViewId,
        });
      }

      return {
        sessionId: SessionId,
        pageViewId: PageViewId,
        // TODO: [T1-11350] Rename from floodLightActivityFilters to userAttributionFilters
        floodLightActivityFilters: {
          trackingSchoolCode,
          propertyOrigin,
          campaignType,
          isClickUser,
          adPlatformSource,
        },
      };
    });

  return retryablePromise(sessionIdRequest, {
    maxRetryAttempts: 5,
    waitTimeBetweenFails: 2000,
  }).catch((error) => {
    throw new Error('Get Session API Frontend API call failed', {
      trace: get(error, 'response.body.trace'),
    });
  });
}

/**
 * @summary function to run to validate a given phone number
 */
const browserPhonePromiseCache = {};
export function validatePhone(phone) {
  if (browserPhonePromiseCache[phone]) {
    return browserPhonePromiseCache[phone];
  }

  browserPhonePromiseCache[phone] = request({
    method: 'post',
    url: `${TRIAD_PROXY_ROUTE}/npaNxxCheck`,
    body: {
      phoneNumber: phone,
    },
  }).then(({ phoneNumber }) => {
    const validationResults = { isValid: !!phoneNumber };

    return validationResults;
  });

  return browserPhonePromiseCache[phone];
}

/**
 * @summary Use this to get a user's profile info
 * @param {Array} taxonomyValues.degrees - the degrees associated with the current page
 * @param {Array} taxonomyValues.parentCategories - the parentCategories associated with the current page
 * @param {Array} taxonomyValues.categories - the categories associated with the current page
 */
export function getUserProfile(taxonomyValues) {
  const parentCategoryGuid = taxonomyValues?.parentCategories[0];
  const { queryParamMap } = getStoredQueryParams();
  return request({
    method: 'post',
    url: `${TRIAD_PROXY_ROUTE}/userProfile`,
    body: {
      parentCategoryGuid,
      [SHARED_EDU_ID]: queryParamMap[SHARED_EDU_ID],
      [SHARED_SESSION_ID]: queryParamMap[SHARED_SESSION_ID],
      includeGeoLocation: false,
    },
  });
}

export function extractAndSortTaxonomy(children, level = 1) {
  return children.map((child) => ({
    level,
    orderBy: child.orderBy || null,
    value: child.value,
    label: child.label,
    children: extractAndSortTaxonomy(
      child.parentCategories || child.categories || [],
      level + 1
    ),
  }));
}

/**
 * @summary this will get the navigation for the Click Portal
 */
export async function getProgramTaxonomy() {
  return request({
    method: 'get',
    url: `${TRIADMS_BACKEND}/ClickPortal/GetProgramTaxonomy`,
  }).then((res) => {
    if (!res.IsValid) {
      LogError(res.Error[0]);
      throw new Error(res.Error[0]);
    }

    return {
      degrees: extractAndSortTaxonomy(res.degreeTypes),
    };
  });
}

function parseSchoolDescription(desc) {
  if (!desc) {
    return 'Description not available please check back soon.';
  }

  return desc.map((txt) => `<p>${txt}</p>`).join();
}

function getMicroPortalPostLeadSubmitAdditionalSchoolResults() {
  return request({
    method: 'post',
    url: `${TRIAD_PROXY_ROUTE}/microportal/GetMPAdditionalSchoolListings`,
  }).then(({ Listings, PhoneNumber }) => {
    return {
      phoneNumber: PhoneNumber,
      listings: Listings?.map((result) => {
        return {
          schoolGuid: result.value,
          schoolCode: result.schoolCode,
          schoolName: result.schoolName,
          impressionGuid: result.impressionGuid,
          label: result.label,
          rating: result.rating,
          description: parseSchoolDescription(result.schoolDesc),
          programs: result.programOptions[0]?.options,
          schoolLogo: getSchoolLogoUrlMap(result.schoolImages),
        };
      }),
    };
  });
}

export async function getMicroPortalPostLeadSubmitQuestionnaire({
  schoolCode,
}) {
  const schoolResults =
    await getMicroPortalPostLeadSubmitAdditionalSchoolResults();

  const questions = schoolResults.listings.map((school) => {
    return {
      grouping: 1,
      fieldGroupName: 'additionalResults',
      // TODO: [T1-10233] we should clean up core form logic to only need questionId or id and normalize the parsed backend question bank response respectively
      questionId: school.schoolGuid,
      id: school.schoolGuid,
      label: school.label,
      name: school?.schoolGuid?.toString(),
      type: 'SELECTION_CARD',
      required: false,
      isPii: false,
      options: school.programs,
      dependency: {},
      meta: {
        description: school.description,
        rating: school.rating,
        schoolLogo: school.schoolLogo,
        schoolName: school.schoolName,
        impressionGuid: school.impressionGuid,
      },
    };
  });

  questions.push({
    grouping: 1,
    questionId: QUESTION_IDS.MICRO_PORTAL_DYNAMIC_TCPA,
    id: QUESTION_IDS.MICRO_PORTAL_DYNAMIC_TCPA,
    label: 'second_page_tcpa',
    name: 'second_page_tcpa',
    type: 'ADDITIONAL_SCHOOLS_DISCLAIMER',
    required: true,
    isPii: false,
    options: [],
    meta: { phoneNumber: schoolResults?.phoneNumber },
  });

  return {
    schoolCode,
    variant: null,
    id: 'microPortalQuestionnaire',
    subTitle: null,
    title: null,
    stepsCount: 1,

    steps: [
      {
        questions,
        id: 1,
        trueStepIndex: 0,
        title: null,
        subTitle: null,
        heading: null,
        progressMeter: null,
        groupLabel: 'You Might Also Be Interested In',
      },
    ],
  };
}

/*
 * @param {String} schoolCode - the school code for the current school
 */
// TODO: [T1-11350] Rename from fetchFloodlightActivityValues to fetchAvailableAttributionValues
export function fetchFloodlightActivityValues(schoolCode) {
  if (!schoolCode) {
    LogError('fetchFloodlightActivityValues: No School Code');
    return Promise.resolve({});
  }

  return request({
    method: 'post',
    url: `${TRIADMS_BACKEND}/GetCampaignManagerActivityValues`,
    body: {
      schoolCode,
    },
  })
    .then((res) => {
      return {
        eventValues: res.tagEvents.map(extractUserTrackingFilters) || [],
      };
    })
    .catch((error) => {
      LogError(`fetchFloodlightActivityValues: ${error.message}`);
      return {
        eventValues: [],
      };
    });
}

export async function getLeadEvalToken(
  formValues,
  fieldNameMap,
  schoolCode,
  metaData = {}
) {
  const { isClickUser } = metaData;

  const response = await request({
    method: 'post',
    url: `${TRIAD_PROXY_ROUTE}/getLeadEvalToken`,
    cacheKey: JSON.stringify(formValues),
    useMemoryCache: true,
    body: {
      schoolCode,
      trustedFormUrl: getDOMFieldValue('xxTrustedFormCertUrl_0'),
      leadId: getDOMFieldValue('leadid_token'),
      questionReplies: formValuesToRequestArr(formValues, fieldNameMap),
      isClickUser,
    },
  });

  if (!response.leadEvalToken) {
    throw new Error('No lead eval token returned');
  }

  return { leadEvalToken: response.leadEvalToken };
}
