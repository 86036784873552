/* eslint-disable no-use-before-define */
import { FIELD_TYPES } from 'components/AVAILABLE_COMPONENTS';
import {
  FIELD_NAMES,
  QUESTION_IDS,
  SKIP_REASON_CODES,
  FormFieldValidationStatus,
  DEPENDENCIES,
  VALIDATION_TYPES,
} from 'consts';
import { NextApiRequest } from 'next';

// ----------------------- //
// ------- Global -------- //
// ----------------------- //
declare global {
  interface Window {
    triadGrowthBookAPI?: GrowthBookAPI;
    isABTestingEnabled: boolean;
  }
}

export type TriadmsRequest = NextApiRequest & {
  redisHelpers: RedisHelpers;
};

export interface TriadmsResponse<T> {
  success: boolean;
  error: string;
  data: T;
}

export interface RedisHelpers {
  getQuestionnaire: (variant: string) => Promise<Questionnaire | undefined>;
  setQuestionnaire: (variant: string, questionnaire: Questionnaire) => void;
}

type FormFieldValue =
  | FormFieldValueObject<string>
  | FormFieldValueObject<string>[]
  | FormFieldValueObject<MultiSchoolSelectionState>
  | FormFieldValueObject<GooglePlace>
  | null
  | boolean
  | string;

export type FormValues = {
  [__T in FIELD_NAMES]?: FormFieldValue;
};

export interface FormFieldValueObject<T> {
  shouldSkip?: boolean;
  skipReason?: SKIP_REASON_CODES;
  label?: string;
  value: T;
  valueToSubmit?: string;
  guid?: string;
}

export interface FormStatus {
  stepsValidationStatus: StepValidationInfo[];
  isProcessing: boolean;
  isSubmitting: boolean;
  dynamicOptions: {
    [__T in QUESTION_IDS]?: { options: SelectFieldOption[] | School[] };
  };
}

export interface Step {
  groupLabel?: string;
  heading: string;
  id: number;
  name: string;
  subTitle?: string;
  title?: string;
  questions: Question[];
  trueStepIndex?: number;
  progressMeterRange?: string;
  subStepIndex?: number;
}

export type LeadSubmitBatchId = string & { __id: 'LeadSubmitBatchId' };

export type SchoolImpressionGuid = string & { __id: 'SchoolImpressionGuid' };

export interface SelectFieldOption {
  value: string;
  label: string;
  selected: boolean;
  guid?: string;
}

export interface GroupedOptions {
  group: string;
  options: SelectFieldOption[];
}

export type OptionsArray = SelectFieldOption[] | GroupedOptions[];

export interface Question {
  name: FIELD_NAMES;
  label: string;
  skipValue?: string;
  isPii?: boolean;
  id: QUESTION_IDS;
  grouping: number;
  required: boolean;
  validations: {
    type: VALIDATION_TYPES;
    errorText: string;
  }[];
  questionId: QUESTION_IDS; // we need to remove this
  type: FIELD_TYPES;
  options?: OptionsArray;
  dependency?: {
    [__T in DEPENDENCIES]?: {
      type: __T;
      questionIds: QUESTION_IDS[];
      validAnswers: {
        [__R in QUESTION_IDS]?: QUESTION_IDS[];
      };
    };
  };
  additionalProperties: {
    mobile?: Record<string, unknown>;
    tablet?: Record<string, unknown>;
    desktop?: Record<string, unknown>;
  };
  controlOverrides: {
    mobile?: FIELD_TYPES;
    tablet?: FIELD_TYPES;
    desktop?: FIELD_TYPES;
  };
}

export interface Questionnaire {
  schoolCode: string;
  variant: string;
  id: number;
  stepsCount: number;
  steps: Step[];
  useLandingPageHeadline?: boolean;
  isFullPage?: boolean;
  disableLogging?: boolean;
  initialStepIndex?: number;
  hasDynamicDisclaimer: boolean;
}

export interface Program {
  value: string;
  label: string;
}

export interface Degree {
  value: string;
  label: string;
  programs: Program[];
}

export interface School {
  degreeList: Degree[];
  description: string;
  featuredImage: { mobile: string; desktop: string };
  highlights: string;
  id: string;
  label: string;
  matchingProgramOptions: OptionsArray;
  originalSelectedProgram: Program;
  rating: number;
  schoolLogo: { mobile: string; desktop: string };
  terms: string;
  impressionGuid: SchoolImpressionGuid;
  impressionGroupGuid: string;
  hasTcpaCheckbox: boolean;
  submitText: string;
}

export interface BrowserOrServerNewRelicAgent {
  noticeError?: (error: Error) => void;
  addCustomAttribute?: (key: string, value: string) => void;
  addPageAction?: (name: string) => void;
  log?: (name: string, attributes: Record<string, unknown>) => void;
  setErrorHandler?: (handler: (error: Error) => boolean) => void;
  setApplicationVersion?: (version: string) => void;
}

export interface UserTrackingFilters {
  mapKeys: {
    adPlatformDestination: string;
    isConversion: boolean | null;
    eventType: string;
  };
  filters: {
    adPlatformSource: string;
    campaignType: string;
    isClickUser: boolean | null;
    propertyOrigin: string;
    trackingSchoolCode: string;
  };
  google: {
    advertiserId: string;
    groupTagString: string;
    activityTagString: string;
  };
  microsoft: {
    category: string;
    action: string;
    label: string;
    value: number | string;
    uetTagId: string;
  };
}

// ----------------------- //
// -- Field Validations -- //
// ----------------------- //
export interface FieldValidationArguments<T> {
  value: T;
  name: string;
  errorMessage?: string;
  formValues?: FormValues;
}

export interface FieldValidationResult {
  [FIELD_NAMES: string]: string | null;
}

export type FieldValidationFunctionSignature<T> = (
  args: FieldValidationArguments<T>
) => FieldValidationResult | Promise<FieldValidationResult>;

export interface StepValidationInfo {
  status: FormFieldValidationStatus;
  fieldValidations: {
    [__T in FIELD_NAMES]?: FormFieldValidationStatus;
  };
}

// ----------------------- //
// ------- States -------- //
// ----------------------- //
export interface SchoolSelectionState {
  isSelected: boolean;
  isExpanded: boolean;
  isAccepted: boolean;
  isSkipped: boolean;
  isShowingDetails: boolean;
  isSubmitted: boolean;
  selectedProgram: Program;
  schoolInfo: School;
}

export type MultiSchoolSelectionState = Record<string, SchoolSelectionState>;

export enum MultiSchoolSelectActionTypes {
  EXPAND_CARD = 'EXPAND_CARD',
  SKIP_CARD = 'SKIP_CARD',
  TOGGLE_SKIP = 'TOGGLE_SKIP',
  ACCEPT_TERMS = 'ACCEPT_TERMS',
  EXPAND_NEXT_CARD = 'EXPAND_NEXT_CARD',
  TOGGLE_DETAILS = 'TOGGLE_DETAILS',
  CLOSE_CARD = 'CLOSE_CARD',
  UPDATE_PROGRAM = 'UPDATE_PROGRAM',
  INITIALIZE = 'INITIALIZE',
}

export interface MultiSchoolSelectAction {
  type: MultiSchoolSelectActionTypes;
  payload: {
    id?: string;
    program?: Program;
    schools?: School[];
    handleFormFieldChange?: (
      updatedFieldValue: MultiSchoolSelectionState
    ) => void;
    phoneNumber?: string;
    secondaryPhoneNumber?: string;
    disclaimerText?: string;
  };
}

// ----------------------- //
// ------ Contexts ------- //
// ----------------------- //
export interface BasicFormWizardContext {
  allQuestionsInForm: Record<QUESTION_IDS, Question> | null;
  formValues: FormValues;
  formStatus: FormStatus;
  formValuesV2: {
    [__T in FIELD_NAMES]?: {
      status: {
        validationStatus: FormFieldValidationStatus;
      };
    };
  };
  fieldNameMap: { [__T in FIELD_NAMES]?: Question };
  formConfigs: Questionnaire;
  handleChange: (
    fieldValue: FormFieldValue,
    event: { name: FIELD_NAMES; shouldSkipFormChangeFlow?: boolean }
  ) => Promise<{
    isCurrentStepSkipable: boolean;
  }>;
  handleSubmit: () => void;
  currentQuestions: Question[];
  currentStepIndex: number;
  actions: {
    setStatus: (status: Partial<FormStatus>) => void;
  };
}

// ----------------------- //
// ---- API Interfaces ---- //
// ----------------------- //
export interface QuestionRepliesStructureForBackend {}

export interface GetQuestionnaireArgs {
  domain: string;
  variant: string;
  schoolCode: string;
  questionBankMap: { [__T in FIELD_NAMES]?: Question };
  redisHelpers: RedisHelpers;
}

export interface GrowthBookAPI {
  isABTestingEnabled: boolean;
  // https://docs.growthbook.io/features/basics#default-values
  getFeatureValue: (feature: FEATURE_FLAGS) => string | number | JSON;
}

export enum FEATURE_FLAGS {
  ACTIVE_TESTING_VARIANT = 'active-testing-variant',
}

export interface UserTrackingFiltersResponse {
  tagDestination?: string;
  isConversion?: boolean | null;
  eventType?: string;
  adPlatformSource?: string;
  campaignType?: string;
  isClickUser?: boolean | null;
  propertyOrigin?: string;
  trackingSchoolCode?: string;
  gadAdvertiserId?: string;
  gadGroupTagString?: string;
  gadActivityTagString?: string;
  msaCategory?: string;
  msaAction?: string;
  msaLabel?: string;
  msaValue?: string | number;
  msaUetTagID?: string;
}

export interface APIMetaDataInfo {
  schoolCode: string;
  variant: string;
  disclaimerText: string;
}

export interface getQuestionOptionsPayload {
  questionReplies: {
    questionAnswer: string;
    questionId: string;
  }[];
}

export interface GetSchoolListingPayload {
  maxSchools?: number;
  leadEvalToken?: string;
  impressionGroup?: string;
  submittedImpressionGuids?: string[];
  pageTaxonomyValues: {
    degrees?: string;
    parentCategories?: string;
    categories?: string;
  };
  questionReplies: {
    questionAnswer: string;
    questionId: QUESTION_IDS;
  }[];
  requestedOptions: QUESTION_IDS[];
  requestType: 'Primary' | 'Secondary' | 'MicroClickportal';
}

export interface GetQuestionOptionsParsedResponse {
  [key: string]: {
    questionId: string;
    options: SelectFieldOption[] | School[];
  };
}

export interface SchoolOptionAPIResponse {
  HasFeaturedPrograms: boolean;
  IsOffsiteConversion: boolean;
  MatchingCategory: string;
  destinationUrl: string;
  filteredProgramCount: number;
  featuredImage?:
    | {
        Desktop?: { imageUrl: string };
        Mobile?: { imageUrl: string };
      }
    | undefined;
  highlights: string;
  impressionGuid: SchoolImpressionGuid;
  label: string;
  learningEnvironment: string;
  programCount: number;
  programs?: {
    ProgramList?: {
      DegreeTypeGuid: string;
      categoryPriority: number;
      destinationUrl: string;
      label: string;
      matchesFilterTag: boolean;
      shortDesc: string;
      value: string;
    }[];
    value: string;
    label: string;
  }[];
  rating: number;
  schoolCode: string;
  schoolDesc?: string[];
  schoolImages?:
    | {
        Desktop?: { imageUrl: string };
        Mobile?: { imageUrl: string };
      }
    | undefined;
  selectedProgram?: {
    DegreeTypeGuid: string;
    categoryPriority: number;
    destinationUrl: string;
    label: string;
    matchesFilterTag: boolean;
    shortDesc: string;
    value: string;
  };
  value: string;
  disclaimer: string;
  Revenue?: number;
  FilterTagName?: string;
  matchingCategory?: string;
  optIn: boolean;
  submitText: string;
}

export interface GetQuestionOptionsRawResponse {
  questions: { questionId: string; options: SelectFieldOption[] }[];
}

export interface GetSchoolListingRawResponse {
  Listings: SchoolOptionAPIResponse[];
  impressionGroup: string;
  IsValid?: boolean;
  cacheKey?: string;
  Errors?: string[];
  ImpressionKey?: string;
  Headline1?: string;
  Headline2?: string;
  ParentCategory?: string;
  DegreeType?: string;
  Category?: string;
  HasTags?: boolean;
}

export interface LeadSubmitResponse {
  shouldTrackConversion: boolean;
  leadsSubmittedFor: Array<{
    leadGuid: string;
    statusMessage: string;
    status: string;
    revenue: number;
    adjustedRevenue: number;
    schoolCode: string;
  }>;
}

export interface MicroPortalLeadSubmitResults {
  shouldTrackConversion: boolean;
  summary: {
    revenue: number;
    adjustedRevenue: number;
  };
  isWaitMoreResults: boolean;
  leadsSubmittedFor: LeadSubmitResponse['leadsSubmittedFor'];
}

// https://developers.google.com/maps/documentation/geocoding/requests-geocoding#Types
export type GooglePlaceAddressComponentType =
  | 'street_number'
  | 'route'
  | 'locality'
  | 'political'
  | 'administrative_area_level_2'
  | 'administrative_area_level_1'
  | 'country'
  | 'postal_code'
  | 'neighborhood';

export interface GooglePlaceAddressComponent {
  longText: string;
  shortText: string;
  types: GooglePlaceAddressComponentType[];
  languageCode: string;
}

export interface GooglePlace {
  formattedAddress: string;
  placeId?: string;
  staticMapUrl?: string;
  addressComponents: GooglePlaceAddressComponent[];
  location: {
    latitude: number;
    longitude: number;
  };
}

export interface GoogleAutoCompleteResult {
  placePrediction: {
    placeId: string;
    text: {
      text: string;
    };
  };
}

export interface GooglePlacesApiResponse {
  places?: GooglePlace[];
}

export interface GooglePlacesAutoCompleteResponse {
  suggestions: GoogleAutoCompleteResult[];
}

export interface TriadGooglePlaceApiResponse<T> {
  data: T;
  error: string;
  success: boolean;
}

type ScreenWidthType = 'mobile' | 'tablet' | 'desktop';

interface PositioningConfig {
  mobile?: string;
  tablet?: string;
  desktop?: string;
}

interface AdditionalPropertyBase {
  screenWidth: ScreenWidthType[];
}

interface HelpTextProperty extends AdditionalPropertyBase {
  text: string;
  isVisible: boolean;
}

interface ToolTipProperty extends AdditionalPropertyBase {
  text: string;
  label: string;
  positioning: PositioningConfig;
}

interface PlaceholderTextProperty extends AdditionalPropertyBase {
  text: string;
}

interface AnimatedLabelProperty extends AdditionalPropertyBase {
  text: string;
}

interface HiddenLabelProperty extends AdditionalPropertyBase {
  hiddenLabelToggle: boolean;
}

interface WidthProperty extends AdditionalPropertyBase {
  width: string;
}

interface ShouldNotSkipProperty extends AdditionalPropertyBase {
  shouldNotSkip: boolean;
}

export interface AdditionalProperties {
  helpText?: HelpTextProperty;
  toolTip?: ToolTipProperty;
  placeHolderText?: PlaceholderTextProperty;
  animatedLabel?: AnimatedLabelProperty;
  hiddenLabel?: HiddenLabelProperty;
  width?: WidthProperty;
  shouldNotSkip?: ShouldNotSkipProperty;
}

export interface WordpressQuestionnaire {
  questionnaireId: number;
  questionnaireSlug: string;
  questionnaireName: string;
  isDefault?: boolean;
  successRedirectUrl?: string;
  title?: string;
  subTitle?: string;
  groupAttributes?: Record<number, { groupLabel: string }>;
  steps: {
    heading: string;
    title: string;
    subTitle: string;
    progressMeter: string;
    stepId: number;
    questions: {
      id: number;
      questionId: number;
      showCityState: boolean;
      grouping: number;
      additionalProperties?: AdditionalProperties;
      controlOverrides: {
        mobile?: FIELD_TYPES;
        tablet?: FIELD_TYPES;
        desktop?: FIELD_TYPES;
      };
      dependencyOverrides: {
        type: DEPENDENCIES;
        questionIds: QUESTION_IDS[];
        validAnswers: {
          [__R in QUESTION_IDS]?: QUESTION_IDS[];
        };
      };
      label: string;
    }[];
  }[];
  mobileComponent?: string;
  component?: string;
  displayOptions?: {
    showSidebar: boolean;
    showSteps: boolean;
  };
}

// ----------------------- //
// ------ Props ------- //
// ----------------------- //
export interface FormFieldProps<T = SelectFieldOption> {
  name: FIELD_NAMES;
  label?: string;
  error?: string;
  onChange: BasicFormWizardContext['handleChange'];
  value: FormFieldValue;
  onFocus: () => void;
  onBlur: () => void;
  options?: T[];
  required: boolean;
}

// ----------------------- //
// -------- Hooks -------- //
// ----------------------- //
export interface UseMultiSchoolSelect {
  state: MultiSchoolSelectionState;
  handleSelectSchool: (id: string) => void;
  handleSkipSchool: (id: string) => void;
  handleToggleSkip: (id: string) => void;
  handleAcceptTerms: (
    id: string,
    questionReplies: QuestionRepliesStructureForBackend,
    metaData: APIMetaDataInfo
  ) => void;
  handleNextClick: () => void;
  handleToggleDetails: (id: string) => void;
  handleCloseCard: (id: string) => void;
  handleProgramChange: (id: string, program: Program) => void;
}

export interface UseMicroPortalFormHandler {
  onMoveToNextStep: (formContext: BasicFormWizardContext) => void;
  onOptionsRequest: (
    payload: GetSchoolListingPayload,
    schoolCode: string,
    variant: string
  ) => Promise<GetQuestionOptionsParsedResponse>;
  onFormSubmit: () => Promise<MicroPortalLeadSubmitResults>;
  onLogProgress: (
    formValues: BasicFormWizardContext['formValues'],
    fieldNameMap: BasicFormWizardContext['fieldNameMap'],
    formConfigs: BasicFormWizardContext['formConfigs'],
    linkedSessionFormValues: BasicFormWizardContext['formValues'],
    lastQuestionAnswered: Question
  ) => Promise<void>;
}
