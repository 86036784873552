import React, { useContext, useEffect } from 'react';
import useTokenReplacement from 'hooks/custom/useTokenReplacement';
import GlobalContext from 'hooks/contexts/GlobalContext';
import BasicFormWizardContext from 'hooks/contexts/BasicFormWizardContext';
import { FormFieldProps } from 'types';
import { getFirstSchoolListingOption } from 'utils/form-utils/getFirstSchoolListingOption';
import Loader from 'components/base/loader/Loader';
import Disclaimer from '../disclaimer/Disclaimer';

// TODO [T1-11709]: Write Unit Tests for Micro Portal functionality
export function DynamicDisclaimer(
  props: FormFieldProps<boolean | null>
): React.ReactNode {
  const {
    siteMeta: { disclaimerText, disclaimerSuccessText },
    actions,
  } = useContext(GlobalContext);

  const {
    formStatus: { isProcessing, dynamicOptions },
  } = useContext(BasicFormWizardContext);

  // on microportal we want to show the terms of the first school
  const firstSchool = getFirstSchoolListingOption(dynamicOptions);

  // TODO [T1-11709]: Write Unit Tests for Micro Portal functionality
  useEffect(() => {
    actions.updateGlobalFlags({ isLoading: isProcessing });

    if (isProcessing) {
      props.onChange(null, { name: props.name });
    } else if (firstSchool?.hasTcpaCheckbox === false) {
      props.onChange(true, { name: props.name });
    }
  }, [isProcessing, firstSchool?.hasTcpaCheckbox]);

  let _disclaimerText = firstSchool ? disclaimerSuccessText : disclaimerText;

  if (firstSchool?.terms) {
    _disclaimerText = firstSchool.terms;
  }

  const dynamicDisclaimerText = useTokenReplacement(_disclaimerText, {
    '{SELECTED_SCHOOL_NAME}': `<span data-tf-element-role="consent-advertiser-name">${firstSchool?.label}</span>`,
  });

  if (isProcessing) {
    return (
      <div data-testid="disclaimer-loading">
        <Loader text="Searching for schools, please wait..." />
      </div>
    );
  }

  if (!firstSchool) {
    return <div data-testid="disclaimer-no-value" />;
  }

  return (
    <Disclaimer
      disclaimerTextValue={dynamicDisclaimerText}
      onChange={props.onChange}
      value={Boolean(props.value)}
      required={firstSchool.hasTcpaCheckbox}
      name={props.name}
    />
  );
}
