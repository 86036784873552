/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
// TODO [T1-11709]: Write Unit Tests for Micro Portal functionality
import React, { memo, useState } from 'react';

import Wysiwyg from 'components/blocks/wysiwyg/Wysiwyg';
import isBrowser from 'utils/isBrowser';
import { LogError } from 'utils/logging';

interface TermsProps {
  terms: string;
  schoolName: string;
  isSkipped: boolean;
  isAccepted: boolean;
  onTcpaCheckboxToggle: (isAccepted: boolean) => void;
  hasTcpaCheckbox: boolean;
}

let TCP_COUNTER = 1;
const ELEMENTS_ID: Record<string, number> = {};

function getElementId(schoolName: string): number {
  if (!isBrowser()) {
    LogError('Do not run this on server');
    return 0;
  }

  if (!ELEMENTS_ID[schoolName]) {
    ELEMENTS_ID[schoolName] = TCP_COUNTER;
    TCP_COUNTER += 1;
  }

  return ELEMENTS_ID[schoolName];
}

function getTCPElementId(schoolName: string): string {
  return `leadid_tcpa_disclosure_${getElementId(schoolName)}`;
}

function getBrandNameElementId(schoolName: string): string {
  return `leadid_tcpa_brandname_0${getElementId(schoolName)}`;
}

/**
 * @see https://marketing.verisk.com/helpcenter/docs/how-to-add-jornaya-tcpa-label-tag#yesno-menu
 * @see https://marketing.verisk.com/docs/how-to-add-tcpa-brand-name-labels
 */
function JornayaInput(props: {
  schoolName: string;
  inputName: string;
  isSkipped: boolean;
  isAccepted: boolean;
}): React.JSX.Element | null {
  const { isSkipped, isAccepted, inputName, schoolName } = props;

  const shouldRenderInput = isSkipped || isAccepted;

  if (!shouldRenderInput) return null;

  return (
    <>
      <input type="hidden" id={getTCPElementId(schoolName)} />
      <label
        style={{ display: 'none' }}
        htmlFor={getBrandNameElementId(schoolName)}
      >
        {schoolName}
      </label>
      <select
        name={inputName}
        id={getBrandNameElementId(schoolName)}
        value={isAccepted ? 'accepted' : 'skipped'}
        style={{ display: 'none' }}
      >
        <option value="accepted">Yes</option>
        <option value="skipped">No</option>
      </select>
    </>
  );
}

const MemoizedJornayaInput = memo(JornayaInput, (prevProps, nextProps) => {
  return (
    prevProps.isAccepted === nextProps.isAccepted &&
    prevProps.isSkipped === nextProps.isSkipped
  );
});

export function Terms(props: TermsProps): React.JSX.Element {
  const { terms, schoolName } = props;
  const [isAccepted, setIsAccepted] = useState(false);

  const inputName = `terms-${schoolName
    ?.trim()
    .toLowerCase()
    .replace(/\s/g, '_')
    .substring(0, 20)}`;

  function handleCheckboxChange(e: React.ChangeEvent<HTMLInputElement>): void {
    setIsAccepted(e.target.checked);
    props.onTcpaCheckboxToggle(e.target.checked);
  }

  function handleTermsClick(): void {
    if (!props.hasTcpaCheckbox) return;
    const newValue = !isAccepted;
    setIsAccepted(newValue);
    props.onTcpaCheckboxToggle(newValue);
  }

  return (
    <div className="terms">
      {props.hasTcpaCheckbox && (
        <>
          <input
            checked={isAccepted}
            className="terms__checkbox"
            id={`${getTCPElementId(schoolName)}_checkbox`}
            onChange={handleCheckboxChange}
            type="checkbox"
            data-testid="disclaimer"
            data-tf-element-role="consent-opt-in"
          />
          <label
            className="terms__checkboxLabel"
            htmlFor={`${getTCPElementId(schoolName)}_checkbox`}
          >
            <span className="terms__checkboxLabelText">I accept the terms</span>
          </label>
        </>
      )}
      <label
        htmlFor={getTCPElementId(schoolName)}
        data-tf-element-role="consent-language"
        className="terms__label"
        onClick={handleTermsClick}
        style={{ cursor: props.hasTcpaCheckbox ? 'pointer' : 'default' }}
      >
        <Wysiwyg content={terms} />
      </label>

      <MemoizedJornayaInput
        schoolName={schoolName}
        inputName={inputName}
        isAccepted={props.isAccepted}
        isSkipped={props.isSkipped}
      />
    </div>
  );
}
