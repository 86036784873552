import { useRouter } from 'next/router';
import { logProgress } from 'app-requests/triadmsRequests';
import { getNewLeadSubmitBatchId } from 'app-requests/triadms-apis/getNewLeadSubmitBatchId';
import promiseDebounce from 'utils/promiseDebounce';
import {
  QUESTION_IDS,
  MICRO_PORTAL_ENDPOINTS,
  clickPortalRedirect,
  FIELD_NAMES,
} from 'consts';
import { retryablePromise } from 'utils/generalUtils';
import { LogError, LogInfo } from 'utils/logging';
import { getQuestionOptions } from 'app-requests/triadms-apis/getQuestionOptions';
import { getMicroPortalSchoolListing } from 'app-requests/triadms-apis/getMicroPortalSchoolListing';
import {
  BasicFormWizardContext,
  BasicFormWizardContext as ContextType,
  GetQuestionOptionsParsedResponse,
  GetSchoolListingPayload,
  MicroPortalLeadSubmitResults,
  Question,
  Questionnaire,
  UseMicroPortalFormHandler,
} from 'types';
import { useContext } from 'react';
import GlobalContext from 'hooks/contexts/GlobalContext';
import {
  startLeadSubmitPolling,
  submitSingleMicroPortalLead,
} from 'app-requests/triadms-apis/processMicroPortalLead';
import { handleConversionTracking } from 'utils/handleConversionTracking';
import { formValuesToRequestArr } from 'utils/form-utils/formValuesToRequestArr';
import { getFirstSchoolListingOption } from 'utils/form-utils/getFirstSchoolListingOption';
import { tokenReplacement } from 'hooks/custom/useTokenReplacement';

const { MICRO_PORTAL_PRIMARY_SCHOOLS_SELECTION, PRIMARY_PHONE } = QUESTION_IDS;

const { SECONDARY_PHONE } = FIELD_NAMES;

const debouncedRequestOptions = promiseDebounce(getQuestionOptions, 600);

const debouncedSchoolSelectionRequestOptions = promiseDebounce(
  (
    payload: GetSchoolListingPayload,
    schoolCode: string,
    variant: string,
    endpoint: string
  ) =>
    retryablePromise(
      () => getMicroPortalSchoolListing(payload, schoolCode, variant, endpoint),
      {
        maxRetryAttempts: 3,
        waitTimeBetweenFails: 2000,
      }
    ).catch((error: Error) => {
      LogError('School Selection Api on the micro portal has failed', {
        error,
      });
      return { [MICRO_PORTAL_PRIMARY_SCHOOLS_SELECTION]: { options: [] } };
    }),
  600
);

/**
 * Custom hook for handling form submission and related functionality in the micro portal.
 */
export default function useMicroPortalFormHandler(): UseMicroPortalFormHandler {
  const router = useRouter();
  const {
    taxonomyValues,
    sessionInfo: { floodLightActivityFilters },
    siteMeta: { floodlightActivityValues, disclaimerSuccessText },
    questionnaire,
  } = useContext(GlobalContext);

  const _questionnaire = questionnaire as Questionnaire;

  /**
   * @summary function that runs when the form is trying to request options for a field
   */
  function onOptionsRequest(
    payload: GetSchoolListingPayload,
    schoolCode: string,
    variant: string
  ): Promise<GetQuestionOptionsParsedResponse> {
    const optionPromises = [];

    // If we need School options call this separately
    if (
      payload.requestedOptions.includes(MICRO_PORTAL_PRIMARY_SCHOOLS_SELECTION)
    ) {
      const schoolSelectionPayload = {
        ...payload,
        maxSchools: _questionnaire.hasDynamicDisclaimer ? 5 : 4,
        requestType: 'Primary',
        pageTaxonomyValues: {
          categories: taxonomyValues?.categories?.[0],
          degrees: taxonomyValues?.degrees?.[0],
          parentCategories: taxonomyValues?.parentCategories?.[0],
        },
      };
      schoolSelectionPayload.requestedOptions = [
        MICRO_PORTAL_PRIMARY_SCHOOLS_SELECTION,
      ];
      optionPromises.push(
        debouncedSchoolSelectionRequestOptions(
          schoolSelectionPayload,
          schoolCode,
          variant,
          MICRO_PORTAL_ENDPOINTS.SCHOOL_OPTIONS_ENDPOINT
        )
      );

      // We are just priming cache
      getNewLeadSubmitBatchId();
    }

    // Its possible that the School Listing question is also called with other regular questions
    const nonSchoolSelectionQuestions = payload.requestedOptions.filter(
      (questionId) => questionId !== MICRO_PORTAL_PRIMARY_SCHOOLS_SELECTION
    );
    if (nonSchoolSelectionQuestions.length) {
      const nonSchoolSelectionPayload = { ...payload };
      nonSchoolSelectionPayload.requestedOptions = nonSchoolSelectionQuestions;
      optionPromises.push(
        debouncedRequestOptions(
          nonSchoolSelectionPayload,
          schoolCode,
          variant,
          MICRO_PORTAL_ENDPOINTS.QUESTION_OPTIONS_ENDPOINT,
          taxonomyValues
        )
      );
    }

    return Promise.all(optionPromises).then((responses) => {
      return responses.reduce(
        (allOptions, currentOptions) => ({ ...allOptions, ...currentOptions }),
        {}
      );
    });
  }

  /**
   * @summary use this to log current progress for user's session so we can get it back in the getProfile API
   */
  function onLogProgress(
    formValues: ContextType['formValues'],
    fieldNameMap: ContextType['fieldNameMap'],
    formConfigs: ContextType['formConfigs'],
    linkedSessionFormValues: ContextType['formValues'],
    lastQuestionAnswered: Question
  ): Promise<void> {
    return logProgress(
      formValues,
      fieldNameMap,
      formConfigs,
      linkedSessionFormValues,
      lastQuestionAnswered,
      MICRO_PORTAL_ENDPOINTS.LOG_PROGRESS_ENDPOINT
    );
  }

  /**
   * @summary pushes to click portal like page after form submission
   */
  function onFormSubmit(): Promise<MicroPortalLeadSubmitResults> {
    return new Promise((resolve) => {
      router.push(clickPortalRedirect).then(async () => {
        // We are doing that at the useMultiSchoolSelect Level
        resolve({
          shouldTrackConversion: false,
          summary: {
            revenue: 0,
            adjustedRevenue: 0,
          },
          isWaitMoreResults: false,
          leadsSubmittedFor: [],
        });
      });
    });
  }

  // TODO [T1-11709]: Write Unit Tests for Micro Portal functionality
  // TODO: duplicate code to handleAcceptTerms in useMultiSchoolSelect. Consolidate
  async function handleSubmitUserOnPhoneQuestion(
    formContext: BasicFormWizardContext
  ): Promise<void> {
    const batchId = await getNewLeadSubmitBatchId();

    const schoolListingCount = Object.values(
      formContext.formStatus.dynamicOptions[
        MICRO_PORTAL_PRIMARY_SCHOOLS_SELECTION
      ]?.options || []
    ).length;

    const firstSchool = getFirstSchoolListingOption(
      formContext.formStatus.dynamicOptions
    );

    let phoneNumber = '';
    let secondaryPhoneNumber = '';
    if (typeof formContext.formValues[SECONDARY_PHONE] === 'string') {
      secondaryPhoneNumber = formContext.formValues[SECONDARY_PHONE];
    }

    if (formContext.allQuestionsInForm?.[PRIMARY_PHONE]?.name) {
      const phoneFieldName = formContext.allQuestionsInForm[PRIMARY_PHONE].name;
      phoneNumber = formContext.formValues[phoneFieldName] as string;
    }

    const questionReplies = formValuesToRequestArr(
      formContext.formValues,
      formContext.fieldNameMap
    );

    const metaData = {
      schoolCode: formContext.formConfigs.schoolCode,
      variant: formContext.formConfigs.variant,
      disclaimerText: tokenReplacement(
        firstSchool?.terms || (disclaimerSuccessText as string),
        {
          '{SELECTED_SCHOOL_NAME}': `<span data-tf-element-role="consent-advertiser-name">${firstSchool?.label}</span>`,
        },
        { phoneNumber, secondaryPhoneNumber }
      ),
    };

    await submitSingleMicroPortalLead(
      batchId,
      firstSchool?.impressionGuid || null,
      questionReplies,
      metaData,
      firstSchool?.originalSelectedProgram.value || null
    );

    if (schoolListingCount <= 1) {
      router.push(clickPortalRedirect);
    }

    LogInfo('Starting lead submit polling', {
      description: `${batchId} polling start`,
    });
    const results = await startLeadSubmitPolling(
      /* LeadSubmitBatchId */ batchId,
      /* numberOfResultsExpected */ 1
    );
    LogInfo('Ending lead submit polling', {
      description: `${batchId} polling end. shouldTrackConversion: ${results.shouldTrackConversion}`,
    });
    handleConversionTracking(results, formContext.formConfigs, {
      floodLightActivityFilters,
      floodlightActivityValues,
    });
  }

  function onMoveToNextStep(formContext: BasicFormWizardContext): void {
    const isPhoneStep = formContext.currentQuestions
      .map((question) => question.id)
      .includes(QUESTION_IDS.PRIMARY_PHONE);

    if (_questionnaire.hasDynamicDisclaimer && isPhoneStep) {
      handleSubmitUserOnPhoneQuestion(formContext);
    }

    if (
      formContext.currentStepIndex ===
      formContext.formConfigs.steps.length - 1
    ) {
      router.prefetch(clickPortalRedirect);
    }
  }

  return {
    onLogProgress,
    onOptionsRequest,
    onFormSubmit,
    onMoveToNextStep,
  };
}
